<template>
  <div class="charge-content flex-column">
    <div class="title-wrapper flexrow-column-center">
      <span class="iconfont iconfanhui_fanhui font-size-20 font-black pointer" @click="onClickBack"></span>
      <span class="font-size-16 font-black font-bold" style="margin-left:.5rem">充值</span>
    </div>
    <div class="content-wrapper flex-column">
      <div class="flexrow-column-center" style="margin-top:1.5rem">
        <span class="font-size-16 font-black" style="margin-left:1.5rem">当前余额：</span>
        <span class="font-size-30 font-black" style="margin-left:.625rem">{{'¥' + financeInfo.balance}}</span>
      </div>
     <el-form class="charge-form"  ref="chargeForm" :rules="rules" :model="chargeForm">
          <el-form-item  prop="chargeMoney" label="充值金额："    label-width="88px" >
          <el-input style="max-width:200px;" type="value" v-model.number="chargeForm.chargeMoney"  placeholder="请输入充值金额"
         autocomplete="off" >
          <span slot="prefix" style="padding-left:.5rem;color:#000">¥</span>
         </el-input>

          </el-form-item>

       </el-form>
      <!-- <div class="charge-select-wrapper flexrow-column-center pointer" 
        :class="{select: selectType==0}"
        style="margin-top:2rem;margin-left:1.5rem"
        @click="onClickSelect(0)">
        <div class="point-wrapper flex-all-center" :class="{select: selectType==0}">
          <div v-if="selectType==0" class="point-radio"></div>
        </div>
        <span class="iconfont iconzhifubao font-size-30 font-3468FE"></span>
        <span class="font-size-16 font-313131" style="margin-left:.5rem">支付宝</span>
      </div> -->
      <div class="charge-select-wrapper flexrow-column-center pointer" 
        :class="{select: selectType==1}"
        style="margin-left:1.5rem"
        @click="onClickSelect(1)">
        <div class="point-wrapper flex-all-center" :class="{select: selectType==1}">
          <div v-if="selectType==1" class="point-radio"></div>
        </div>
        <span class="iconfont iconweixinzhifu font-size-30 font-19BE6B"></span>
        <span class="font-size-16 font-313131" style="margin-left:.5rem">微信支付</span>
      </div>
      <div class="charge-select-wrapper flexrow-column-center pointer" 
        :class="{select: selectType==2}"
        style="margin-top:.75rem;margin-left:1.5rem;margin-bottom:1.25rem"
        @click="onClickSelect(2)">
        <div class="point-wrapper flex-all-center" :class="{select: selectType==2}">
          <div v-if="selectType==2" class="point-radio"></div>
        </div>
        <span class="iconfont iconxianxia font-size-30 font-F7A923"></span>
        <span class="font-size-16 font-313131" style="margin-left:.5rem">线下支付</span>
      </div>
      <div v-if="selectType==2" class="account-info-wrapper flex-column">
        <span class="font-size-14 font-opacity-45">请您通过网银转账，或者自行到银行进行汇款，汇款账号如下：</span>
        <div class="flex-row" style="margin-top:1.25rem">
          <span class="font-size-14 font-313131">开户名称：</span>
          <span class="font-size-14 font-3468FE">{{chargeAccountInfo.account_name}}</span>
        </div>
        <div class="flex-row" style="margin-top:1.25rem">
          <span class="font-size-14 font-313131">开户行账号：</span>
          <span class="font-size-14 font-3468FE">{{chargeAccountInfo.account_number + '（' + chargeAccountInfo.account_bank + '）'}}</span>
        </div>
      </div>
      <div v-else class="charge-btn font-size-14 font-white flex-all-center pointer" 
        :class="{light: chargeForm.chargeMoney!==''}"
        @click="onClickCharge">充值</div>
    </div>
    <PayPopComp v-if="isShowPayPop" 
      :rechargeData="currentRechargeData"
      @onClose='onPayPopClose'/>
  </div>
</template>

<script>
import request from "@request/api/index";
import PayPopComp from "./components/PayPopComp"
export default {
  name: "Charge",
  data() {
    return {
      chargeMoney:"",
      chargeForm:{
          chargeMoney:''
      },
      selectType:1,//支付方式：0.微信 1.支付宝 2.线下
      currentRechargeData:{},//当前充值订单的数据
      isShowPayPop:false,
      rules:{
        chargeMoney:[
          {
            type: 'number',
            message: '必须为数字'
          },
          { 
            pattern: /^([1-9]\d{0,6}|10000000)$/,
            message: '可输入范围1到10000000'
          },
        ]
      }
    };
  },
  computed:{
    financeInfo(){
      return this.$store.state.user.financeInfo;
    },
    chargeAccountInfo(){
      return this.$store.state.user.chargeAccountInfo;
    }
  },
  created(){
    this.requestAccountInfo();
  },
  methods: {
    requestAccountInfo(){
      request.charge.requestAccountInfo();
    },
    requestWechatPay(price){
      request.charge.requestWechatPay({price}).then(res=>{
        this.currentRechargeData = {pays:price, code:res.data.data.qrcode, order_sn:res.data.data.order_sn};
        this.isShowPayPop = true;
      }).catch(err=>{
        console.log(err);
      });
    },
    onClickBack(){
      if (window.history.length <= 1) {
        this.$router.replace('billList');
      }else{
        this.$router.back();
      }
    },
    onClickSelect(type){
      if(this.selectType !== type){
        this.selectType = type;
      }
    },
    onClickCharge(){
     
      this.$refs.chargeForm.validate((valid)=>{
          if(valid){
            this.requestWechatPay(this.chargeForm.chargeMoney);
          }
      })  
    },
    onPayPopClose(){
      this.isShowPayPop = false;
    }
  },
  components:{
    PayPopComp
  }
};
</script>

<style lang="scss" scoped>
.charge-content {
  width: 100%;
  min-width: 1184px;
  height: 100%;
  .title-wrapper{
    height: 3.87rem;
  }
  .charge-form{
    margin-top: 1.25rem;
    margin-bottom: 1rem;
  }
  .content-wrapper{
    width:100%;
    background: #FFFFFF;
    border-radius: 8px;
    .input-wrapper{
      width: 220px;
      height: 32px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      .charge-input{
        width:85%;
        margin:0 8px 0 8px;
        border: 0;
      }
    }
    .charge-select-wrapper{
      width: 512px;
      height: 64px;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      &.select{
        border: 1px solid #3468FE;
      }
    }
    .point-wrapper{
      width: 16px;
      height: 16px;
      border: 1px solid #979797;
      border-radius: 50%;
      margin-left: 22px;
      margin-right: 16px;
      &.select{
        border: 1px solid #3468FE;
      }
      .point-radio{
        width: 8px;
        height: 8px;
        background: #3468FE;
        border-radius: 50%;
      }
    }
    .account-info-wrapper{
      margin-left: 24px;
      margin-bottom:30px;
    }
    .charge-btn{
      width: 80px;
      height: 40px;
      background: gray;
      border-radius: 4px;
      margin-left: 24px;
      margin-bottom:53px;
      &.light{
        background: #3468FE;
      }
    }
  }
}
</style>