

<template>
  <el-dialog title="微信支付" :visible="true" width="36%" :before-close="handleClose">
    <div class="content-wrapper flex-column flex-all-center">
      <div class="des-wrapper font-size-30 font-black">{{'¥'+rechargeData.pays}}</div>
      <div class="qr-box">
        <div id="qrcode" ref="qrcode"></div>
        <div v-if="isPayComplete" class="complete-box flex-all-center">支付完成</div>
      </div>
      <div class="flex-row" style="margin-top:2rem">
        <span class="iconfont iconweixinzhifu font-size-30 font-19BE6B"></span>
        <span class="font-size-16 font-313131" style="margin-left:.5rem">请使用微信扫码支付</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import request from "@request/api/index";
import QRCode from 'qrcodejs2'
export default {
  props:{
    rechargeData:Object,
  },
  data() {
    return {
      timer:null,
      isPayComplete:false,
      qrCode:null
    };
  },
  mounted(){
    this.initQr();
  },
  methods: {
    handleClose(){
      this.$emit('onClose');
    },
    async requestRechargeIsPay(){
      const res = await request.charge.requestRechargeIsPay({order_sn:this.rechargeData.order_sn});
      const status = res.data.data.status;
      if(status === 0){
        this.createScene();
      }else if(status === 1){
        this.$message.success('支付成功');
        this.isPayComplete = true;
        request.bill.requestFinanceInfo();
      }else{
        this.$message.error('支付失败');
      }
    },
    initQr(){
      setTimeout(() => {
        this.qrCode = new QRCode(this.$refs.qrcode,{
          width:192,                     //二维码的宽度
          height:192,                    //二维码的高度
          text:this.rechargeData.code,   //后台传的code
        });
        this.createScene();
      }, 500);
    },
    createScene(){
      this.clearSceneTimeout();
      this.timer = setTimeout(() => {
        this.requestRechargeIsPay();
      }, 1000);
    },
    clearSceneTimeout(){
      if(this.timer){
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
  },
  beforeDestroy(){
      this.clearSceneTimeout();
  },
};
</script>
<style lang="scss" scoped>
.content-wrapper{
  width:100%;
  height:100%;
  .qr-box{
    position: relative;
    width: 256px;
    height: 256px;
    margin-top:31px;
    .complete-box{
      position: absolute;
      width:100%;
      height:100%;
      top: 0;
      color: white;
      font-size: 36px;
      background: rgba($color: #000000, $alpha: 0.7);
    }
  }
}
</style>
